import { Injectable } from "@angular/core";
import { ConstantsService } from "../../shared/services/constants.service";
import { HttpServiceService } from "../../shared/services/http-service.service";
import { ToastrService } from "ngx-toastr";
import {
    NgbActiveModal,
    NgbModal,
    NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import { Router } from "@angular/router";
import "angular2-navigate-with-data";
import * as _ from "lodash";
import { WebSocketService } from "./web-socket.service";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import * as lodash from "lodash";

@Injectable()
export class CommonService {
    doctorArray = [];
    userRole = "";
    srNumMarArray = [];
    cityArray = [];
    teamArray = [];
    categoryArray = [];
    vendorArray = [];
    sourceArray = [];
    diseaseArray = [];
    agentArray = [];
    otherTeams: string[] = [];
    marketingUser: string;
    productUser: string;
    navlinksArray = [
        {
            img1: "https://firebasestorage.googleapis.com/v0/b/new-website-14c80.appspot.com/o/bd-dashboard%2Ftop-navigation%2Forder.svg?alt=media",
            img2: "https://firebasestorage.googleapis.com/v0/b/new-website-14c80.appspot.com/o/bd-dashboard%2Ftop-navigation%2Forder-orange.svg?alt=media",
            label: "Marketing",
            route: "/form/marketing",
            isVisible : localStorage.getItem("isAdmin")=='true' || localStorage.getItem("userRole") == 'Marketing'
        },
        {
            img1: "https://firebasestorage.googleapis.com/v0/b/new-website-14c80.appspot.com/o/bd-dashboard%2Ftop-navigation%2Forder.svg?alt=media",
            img2: "https://firebasestorage.googleapis.com/v0/b/new-website-14c80.appspot.com/o/bd-dashboard%2Ftop-navigation%2Forder-orange.svg?alt=media",
            label: "Product",
            route: "/form/product",
            isVisible : localStorage.getItem("isAdmin")=='true' || localStorage.getItem("userRole") == 'Product'

        },
        {
            img1: "https://firebasestorage.googleapis.com/v0/b/new-website-14c80.appspot.com/o/bd-dashboard%2Ftop-navigation%2Forder.svg?alt=media",
            img2: "https://firebasestorage.googleapis.com/v0/b/new-website-14c80.appspot.com/o/bd-dashboard%2Ftop-navigation%2Forder-orange.svg?alt=media",
            label: "Details",
            route: "/details",
            isVisible : localStorage.getItem("isAdmin")=='true'

        }
    ]
    constructor(
        private http: HttpServiceService,
        private constants: ConstantsService,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private datePipe: DatePipe,
        private router: Router,
        private wsService: WebSocketService
    ) {
        const userId = localStorage.getItem("userId");
    }


    makeDateFormat(value) {
        return this.datePipe.transform(value, "yyyy-MM-dd");
    }

    makeDateString(date, time) {
        let tempDate = this.makeDateFormat(date);
        let newDate = new Date(tempDate + "T" + time + "Z");
        return newDate.toISOString();
    }

    sortArrayOfObjects = (array, key) => {
        return array.sort(function (a, b) {
            var keyA = a[key];
            var keyB = b[key];
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });
    };

    sortArrayOfObjectByDate(array, key) {
        return array
            .filter(Boolean)
            .sort(
                (a, b) =>
                    new Date(b[key]).valueOf() - new Date(a[key]).valueOf()
            );
    }

    numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }

    removeArrayDuplicates(array) {
        return array.filter(function (item, pos) {
            return array.indexOf(item) == pos;
        });
    }

    convertToIst(parseTimeObject) {
        let dateString = parseTimeObject;
        let dateObject = new Date(dateString);
        let localTime = dateObject.getTime();
        let localOffSet = dateObject.getTimezoneOffset() * 60000;
        let utc = localTime + localOffSet;
        let offSet = 5.5;
        let indiaDateMS = utc + 3600000 * offSet;
        dateObject = new Date(indiaDateMS);
        let finalDateInIso = dateObject.toJSON();
        return finalDateInIso;
    }

    addTime(time) {
        if (typeof time === "object") {
            return moment(time.iso).format("HH:mm");
        } else {
            return moment(time).format("HH:mm");
        }
    }

    addDays(myDate, days) {
        return new Date(myDate.getTime() + days * 24 * 60 * 60 * 1000);
    }

    returnDate(time) {
        return moment(time).format("YYYY-MM-DD");
    }

    convertSlotFromTimeString(time) {
        let timeObject = new Date(time);
        let hr = timeObject.getHours();
        let mm = timeObject.getMinutes();
        // Converted to string
        let hrNew = "" + hr;
        let mmNew = "" + mm;

        if (hr < 10) {
            hrNew = "0" + hrNew;
        }
        if (mm < 10) {
            mmNew = "0" + mmNew;
        }

        let element = {};
        element["From"] = hrNew;
        element["To"] = mmNew;
        return element;
    }

    makeStringToNumber(obj) {
        if (obj) {
            return {
                hr: parseInt(obj.hr),
                mm: parseInt(obj.mm),
            };
        }
    }

    async downloadFile(fileURL, fileName) {
        if (fileName && fileURL) {
            var save = document.createElement("a");
            let res = await this.getSignedUrl([fileURL]);
            if (res && Array.isArray(res) && res.length > 0) {
                fileURL = res[0];
            }
            save.href = fileURL;
            save.target = "_blank";
            save.download = fileName || "unknown";

            var evt = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: false,
            });
            save.dispatchEvent(evt);

            (window.URL || window["webkitURL"]).revokeObjectURL(save.href);
        }
    }

    makeDropdownArray(array) {
        if (!array || array.length < 1) {
            return [];
        }
        let id = 1;
        let tempArray = [];
        array.forEach((x) => {
            let obj = {};
            obj["id"] = id;
            obj["itemName"] = x;
            tempArray.push(obj);
            id++;
        });
        return _.orderBy(tempArray, "itemName");
    }

    makeDropdownObjArray(array, key) {
        if (!array || array.length < 1) {
            return [];
        }
        let id = 1;
        let tempArray = [];
        array.forEach((x) => {
            let obj = {};
            obj["id"] = id;
            obj["itemName"] = x[key];
            tempArray.push(obj);
            id++;
        });
        return _.orderBy(tempArray, "itemName");
    }

    makeDropdownObjArrayWithId(array, key, keyId) {
        if (!array || array.length < 1) {
            return [];
        }
        let id = 1;
        let tempArray = [];
        array.forEach((x) => {
            let obj = {};
            obj["id"] = x[keyId];
            obj["itemName"] = x[key];
            tempArray.push(obj);
            id++;
        });
        return _.orderBy(tempArray, "itemName");
    }

    zeroPadFunction(num, places) {
        return String(num).padStart(places, "0");
    }

    validateNumber(event: any) {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    checkMobile(mobile) {
        var mobFormat = /^((\\+91-?)|0)?[0-9]{10}$/;
        if (mobFormat.test(mobile) == false) {
            return false;
        } else {
            return true;
        }
    }

    checkAlphabhetLetter(event) {
        const pattern = /^([^0-9]*)$/;
        let inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    returnDateTimeFormatPipe(value) {
        if (value) {
            if (typeof value === "string") {
                return this.datePipe.transform(value, "d MMM yy, h:mm a");
            }
            if (typeof value === "object") {
                return this.datePipe.transform(value.iso, "d MMM yy, h:mm a");
            }
        } else {
            return "NA";
        }
    }

    returnTimeFormatPipe(value) {
        if (value) {
            if (typeof value === "string") {
                return this.datePipe.transform(value, "h:mm a");
            }
            if (typeof value === "object") {
                return this.datePipe.transform(value.iso, "h:mm a");
            }
        } else {
            return "NA";
        }
    }

    returnOnlyDateFormatPipe(value) {
        if (value) {
            if (typeof value === "string") {
                return this.datePipe.transform(value, "d MMM yy");
            }
            if (typeof value === "object") {
                return this.datePipe.transform(value.iso, "d MMM yy");
            }
        } else {
            return "NA";
        }
    }

    async getDiseaseBasedOnCategory(category) {
        let tempAllDiseaseArray = [];
        let res = await this.http.postDataNew(
            this.constants.getDiseaseBasedOnCategory,
            {
                category: category,
            }
        );
        if (res && res["status"] === 200) {
            res["data"].forEach((x) => {
                if (x && x["surgery_name"]) {
                    tempAllDiseaseArray = [
                        ...tempAllDiseaseArray,
                        ...x["surgery_name"].map((x) => x["Surgery"]),
                    ];
                }
            });
        }
        return this.removeArrayDuplicates(tempAllDiseaseArray);
    }

    async getBDBasedOnCategory(category) {
        let tempBDArray = [];
        let reqData = {
            bd_status: [true],
            category: category,
            team: ["Metro"],
        };
        if (
            category.includes("Tertiary") ||
            category.includes("T3") ||
            category.includes("T4") ||
            category.includes("T4") ||
            category.includes("Non-Metro") ||
            category.includes("CentralBD") ||
            category.includes("PXBD")
        ) {
            reqData["category"] = [];
            reqData["team"] = category;
        }
        return this.removeArrayDuplicates(tempBDArray);
    }

    getDifferenceDats(day1, day2, type) {
        var momentDay1 = moment(day1);
        var momentDay2 = moment(day2);
        let requiredDiff = momentDay2.diff(momentDay1, "hours");
        return requiredDiff;
        // if(type === "hour"){
        //     return moment.duration({ "minutes": requiredDiff }).hours()
        // }
        // if(type === "min"){
        //     return moment.duration({ "minutes": requiredDiff }).minutes()
        // }
    }

    getForwardApiName(type, endPoint, data, method = null, routes = null) {
        let obj = {
            type: type,
            endPoint: endPoint,
            data: data,
        };
        if (method) {
            obj["method"] = method;
        }
        if (routes) {
            obj["routes"] = routes;
        }
        return obj;
    }

    async fetchLatestUserValues() {
        if (!localStorage.getItem("isbddashlogin61")) {
            return false;
        }
        let res = await this.http.getData(
            this.constants.getLatestUserData +
                "/" +
                localStorage.getItem("userId")
        );
        if (res && res["status"] === 200 && res["data"]) {
            this.updateUserData(res["data"], false);
            if (res["team"]) {
                localStorage.setItem("Team", res["team"]);
            }
        }
        return true;
    }

    updateUserData(userData, isRoute) {
        let role = userData["role"];
        let otherRoles = userData["otherRoles"];
        // localStorage.removeItem("isAdmin");
        localStorage.removeItem("userRole");
        console.log(userData["isActive"]);
        if (
            userData["isActive"] &&
            ((role && role.match(/srNumberPanel/gi)) ||
                (otherRoles &&
                    Array.isArray(otherRoles) &&
                    otherRoles.length > 0 &&
                    otherRoles.includes("srNumberPanel")))
        ) {
            localStorage.setItem("isbddashlogin61", "true");
            let userEmail = userData["email"];
            let userName = userData["username"];
            let number = userData["Mobile"];
            if (userData && number && number.length > 0) {
                localStorage.setItem("Mobile", number[0]);
            } else {
                this.toastr.info("No User Mobile Number");
            }
            localStorage.setItem("userEmail", userEmail);
            if (userName.includes(".")) {
                userName = userName.split(".").join("");
            }
            localStorage.setItem("userName", userName);

            if (
                role === "srNumberPanelMarketing" ||
                (otherRoles &&
                    Array.isArray(otherRoles) &&
                    otherRoles.length > 0 &&
                    otherRoles.includes("srNumberPanelMarketing"))
            ) {
                localStorage.setItem("userRole", "Marketing");
            }
            if (
                role === "srNumberPanelProduct" ||
                (otherRoles &&
                    Array.isArray(otherRoles) &&
                    otherRoles.length > 0 &&
                    otherRoles.includes("srNumberPanelProduct"))
            ) {
                localStorage.setItem("userRole", "Product");    
            }
            if (
                (otherRoles &&
                    Array.isArray(otherRoles) &&
                    otherRoles.length > 0 &&
                    otherRoles.includes("leadAssignment"))
            ) {
                localStorage.setItem("leadAssignment", "true");    
            }
            if (
                role === "srNumberPanelAdmin" ||
                (otherRoles &&
                    Array.isArray(otherRoles) &&
                    otherRoles.length > 0 &&
                    otherRoles.includes("srNumberPanelAdmin"))
            ) {
                localStorage.setItem("isAdmin", "true");
            }
            if (localStorage.getItem("userRole")) {
                this.userRole = localStorage.getItem("userRole");
            } else {
                this.userRole = "";
            }
            if (isRoute) {
                if (this.userRole) {
                    this.toastr.success("Login Successfully");
                    this.router.navigate([`/form/${this.userRole.toLowerCase()}`] );
                } else {
                    if (localStorage.getItem("isAdmin")) {
                        this.toastr.success("Login Successfully");
                        this.router.navigate(["/details"]);
                    } else {
                        this.toastr.error(
                            "You are not allowed to access this dashboard"
                        );
                        this.router.navigate(["/login"]);
                    }
                }
            }
        } else {
            this.toastr.error("You are not allowed to access this dashboard");
            this.router.navigate(["/login"]);
        }
    }


    getSignedUrlJava(urls) {
        const url = this.constants.signedUrlJava;
        return this.http.postDataNew(url, { urls: [urls] }).then(res => {
          return res['result'][0];
        })
      }

    async getSignedUrl(urls) {
        let signedUrl = [];
        let res = await this.http.postDataNew(this.constants.signedUrl, {
            urls: urls,
        });
        console.log(res);
        if (res && res["status"] === "Success") {
            signedUrl = res["result"];
        }
        return signedUrl;
    }

    diseaseDoctorQuery(category) {
        this.doctorArray = [];
        let reqObj = {};
        if (category) {
            reqObj = {
                team: "Metro",
                category: category,
            };
        }
        return this.http
            .postDataNew(this.constants.bdCategoryWiseList, reqObj)
            .then((res) => {
                if (res["status"] === 200) {
                    return res["data"];
                }
            });
    }

    fetchCommonDataValues() {
        if (this.userRole === "Marketing" || localStorage.getItem("isAdmin")) {
            this.http
                .postDataNew(this.constants.getCommonDataForMarketing, {})
                .then((res) => {
                    
                    if (res && res["status"] === 200) {
                        if (
                            res["data"] &&
                            Object.keys(res["data"]).length > 0
                        ) {
                            if (
                                res["data"]["srnumber"] &&
                                Array.isArray(res["data"]["srnumber"]) &&
                                res["data"]["srnumber"].length > 0
                            ) {
                                this.srNumMarArray = res["data"][
                                    "srnumber"
                                ].map((x) => x["SRNumber"]);
                            }
                            if (
                                res["data"]["city_team"] &&
                                Array.isArray(res["data"]["city_team"]) &&
                                res["data"]["city_team"].length > 0
                            ) {
                                this.cityArray = lodash
                                    .uniq(
                                        JSON.parse(
                                            JSON.stringify(
                                                res["data"]["city_team"]
                                            )
                                        ).map((x) => x["City"])
                                    )
                                    .sort();
                                this.teamArray = lodash
                                    .uniq(
                                        JSON.parse(
                                            JSON.stringify(
                                                res["data"]["city_team"]
                                            )
                                        ).map((x) => x["Team"])
                                    )
                                    .sort();    
                                    
                                    res["data"]["city_team"].map(x => {                                        
                                        if(x["others_team"] && x["others_team"].length){
                                            x["others_team"].map(y => {
                                                this.otherTeams.push(y)
                                            })
                                        }
                                    });

                                    const array3 = this.teamArray.concat(this.otherTeams);
                                    
                                    let uniqueSubjects = array3.filter((team, index) => {
                                        return array3.indexOf(team) === index;
                                    });

                                    this.teamArray = uniqueSubjects
                                    
                                    
                            }
                            if (
                                res["data"]["disease"] &&
                                Array.isArray(res["data"]["disease"]) &&
                                res["data"]["disease"].length > 0
                            ) {
                                this.categoryArray = lodash
                                    .uniq(
                                        res["data"]["disease"].map(
                                            (x) => x["Disease"]
                                        )
                                    )
                                    .sort();
                            }
                            if (
                                res["data"]["source"] &&
                                Array.isArray(res["data"]["source"]) &&
                                res["data"]["source"].length > 0
                            ) {
                                this.sourceArray = lodash
                                    .uniq(
                                        res["data"]["source"]
                                            .map((x) => x["source"])
                                            .filter(Boolean)
                                    )
                                    .sort();
                            }
                        }
                    }
                });
        }
        if (this.userRole === "Product" || localStorage.getItem("isAdmin")) {
            this.http
                .postDataNew(this.constants.getCommonDataForProduct, {})
                .then((res) => {
                    if (res && res["message"] && res["message"] === "success") {
                        if (
                            res["result"] &&
                            Object.keys(res["result"]).length > 0
                        ) {
                            if (
                                res["result"]["vendor"] &&
                                Array.isArray(res["result"]["vendor"]) &&
                                res["result"]["vendor"].length > 0
                            ) {
                                this.vendorArray = res["result"]["vendor"];
                            }
                        }
                    }
                });
        }
    }

    removeDays(myDate, days) {
        return new Date(myDate.getTime() - days * 24 * 60 * 60 * 1000);
    }

    async checkValidityOfOpenedUrl() {
        let validity = true;
        let preNav = []
        let preUrl = window.location.hash.split("#")[1];
        console.log(preUrl);
        console.log(preNav);
        
        
        if (preNav && Array.isArray(preNav) && preNav.length > 0) {
            let index = preNav.findIndex((x) => x["route"] === preUrl);
            if (index === -1) {
                validity = false;
            }
        }
        if (!validity) {
            this.toastr.error("You are not allowed to access this dashboard");
            this.router.navigate(["/login"]);
        }
    }
}
