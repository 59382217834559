export const environment = {
  production: true,
  version: "0.0.0",
  // prod
  docServiceUrl :"http://overhaullb-1186054598.ap-south-1.elb.amazonaws.com/doc",
  // staging
  // docServiceUrl :"http://staging-node-services-1354268930.ap-south-1.elb.amazonaws.com/bch/doc_v2",
  javaUrl : "https://pristyntech.com/java/",
  leadsAlternative : "https://pristyntech.com/leadsAlternative/api"
  //local
  // docServiceUrl :"http://localhost:8080"
};
