import { ConstantsService } from "./constants.service";
import {
    HttpClient,
    HttpClientModule,
    HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class HttpServiceService {
    errUrl =
        "https://edzs926ktc.execute-api.ap-south-1.amazonaws.com/prod/savelog";

    beforeLoginheader = {
        "Content-Type": "application/json",
    };

    header: any;

    constructor(
        private http: HttpClient,
        public router: Router,
        private toastr: ToastrService,
        private constants: ConstantsService
    ) {}

    /**
     *this method posts data to a server
     *@param url - the url to post data to
     *@param data - the data to send to the url
     */

    postDataNew(url, data) {
        if (localStorage.getItem("access_token")) {
            this.header = {
                Authorization:
                    localStorage.getItem("token_type") +
                    " " +
                    localStorage.getItem("access_token"),
                "Content-Type": "application/json",
            };
        } else {
            if (url.match(/request-otp/gi) || url.match(/submit-otp/gi)) {
                this.header = this.beforeLoginheader;
            } else {
                this.header = {
                    Authorization:
                        localStorage.getItem("token_type") +
                        " " +
                        localStorage.getItem("access_token"),
                    "Content-Type": "application/json",
                };
            }
        }
        return new Promise((resolve, reject) => {
            const httpOptions: { headers } = {
                headers: new HttpHeaders(this.header),
            };
            this.http.post(url, data, httpOptions).subscribe(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    console.log(err);
                    if (
                        err["error"] &&
                        err["error"]["error_description"] &&
                        (err["error"]["error_description"] ===
                            "Token has expired" ||
                            err["error"]["error_description"] ===
                                "Token was not recognised")
                    ) {
                        let errPromise = new Promise((resolve, reject) => {
                            const httpOptionsAfter: { headers } = {
                                headers: new HttpHeaders(
                                    this.beforeLoginheader
                                ),
                            };
                            this.http
                                .post(
                                    this.constants.refreshToken,
                                    {
                                        username:
                                            "bdApp_" +
                                            localStorage
                                                .getItem("userEnterMobile")
                                                .trim(),
                                        refresh_token:
                                            localStorage.getItem(
                                                "refresh_token"
                                            ),
                                    },
                                    httpOptionsAfter
                                )
                                .subscribe(
                                    (resNew) => {
                                        Object.entries(resNew).forEach(
                                            ([key, value]) =>
                                                localStorage.setItem(key, value)
                                        );
                                        localStorage.setItem(
                                            "isbddashlogin61",
                                            "true"
                                        );
                                        let newToken = new Promise(
                                            (resolve, reject) => {
                                                const httpOptionsNew: {
                                                    headers;
                                                } = {
                                                    headers: new HttpHeaders({
                                                        Authorization:
                                                            localStorage.getItem(
                                                                "token_type"
                                                            ) +
                                                            " " +
                                                            localStorage.getItem(
                                                                "access_token"
                                                            ),
                                                        "Content-Type":
                                                            "application/json",
                                                    }),
                                                };
                                                this.http
                                                    .post(
                                                        url,
                                                        data,
                                                        httpOptionsNew
                                                    )
                                                    .subscribe((res) => {
                                                        resolve(res);
                                                    });
                                            }
                                        );
                                        resolve(newToken);
                                    },
                                    (errNew) => {
                                        this.toastr.error(
                                            "SESSION HAS EXPIRED\nPLEASE LOGIN AGAIN"
                                        );
                                        localStorage.clear();
                                        this.router.navigate(["/login"]);
                                    }
                                );
                        });
                        resolve(errPromise);
                    } else {
                        this.pushErrorLogToLambda(url, data, err);
                        reject(err);
                    }
                }
            );
        });
    }

    postDataUpload(url, data) {
        if (localStorage.getItem("access_token")) {
            this.header = {
                Authorization:
                    localStorage.getItem("token_type") +
                    " " +
                    localStorage.getItem("access_token"),
            };
        } else {
            if (url.match(/request-otp/gi) || url.match(/submit-otp/gi)) {
                this.header = this.beforeLoginheader;
            } else {
                this.header = {
                    Authorization:
                        localStorage.getItem("token_type") +
                        " " +
                        localStorage.getItem("access_token"),
                    "Content-Type": "application/json",
                };
            }
        }
        return new Promise((resolve, reject) => {
            const httpOptions: { headers } = {
                headers: new HttpHeaders(this.header),
            };
            this.http.post(url, data, httpOptions).subscribe(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    console.log(err);
                    if (
                        err["error"] &&
                        err["error"]["error_description"] &&
                        (err["error"]["error_description"] ===
                            "Token has expired" ||
                            err["error"]["error_description"] ===
                                "Token was not recognised")
                    ) {
                        let errPromise = new Promise((resolve, reject) => {
                            const httpOptionsAfter: { headers } = {
                                headers: new HttpHeaders(
                                    this.beforeLoginheader
                                ),
                            };
                            this.http
                                .post(
                                    this.constants.refreshToken,
                                    {
                                        username:
                                            "bdApp_" +
                                            localStorage
                                                .getItem("userEnterMobile")
                                                .trim(),
                                        refresh_token:
                                            localStorage.getItem(
                                                "refresh_token"
                                            ),
                                    },
                                    httpOptionsAfter
                                )
                                .subscribe(
                                    (resNew) => {
                                        Object.entries(resNew).forEach(
                                            ([key, value]) =>
                                                localStorage.setItem(key, value)
                                        );
                                        localStorage.setItem(
                                            "isbddashlogin61",
                                            "true"
                                        );
                                        let newToken = new Promise(
                                            (resolve, reject) => {
                                                const httpOptionsNew: {
                                                    headers;
                                                } = {
                                                    headers: new HttpHeaders({
                                                        Authorization:
                                                            localStorage.getItem(
                                                                "token_type"
                                                            ) +
                                                            " " +
                                                            localStorage.getItem(
                                                                "access_token"
                                                            ),
                                                        "Content-Type":
                                                            "application/json",
                                                    }),
                                                };
                                                this.http
                                                    .post(
                                                        url,
                                                        data,
                                                        httpOptionsNew
                                                    )
                                                    .subscribe((res) => {
                                                        resolve(res);
                                                    });
                                            }
                                        );
                                        resolve(newToken);
                                    },
                                    (errNew) => {
                                        this.toastr.error(
                                            "SESSION HAS EXPIRED\nPLEASE LOGIN AGAIN"
                                        );
                                        localStorage.clear();
                                        this.router.navigate(["/login"]);
                                    }
                                );
                        });
                        resolve(errPromise);
                    } else {
                        this.pushErrorLogToLambda(url, data, err);
                        reject(err);
                    }
                }
            );
        });
    }

    postDataNew1(url, data) {
        return new Promise((resolve, reject) => {
            const httpOptions: { headers } = {
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                }),
            };
            this.http.post(url, data, httpOptions).subscribe(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    if (err) {
                        this.pushErrorLogToLambda(url, data, err);
                        reject(err);
                    }
                }
            );
        });
    }

    pushErrorLogToLambda(url, reqBody, err) {
        let data = {
            message: JSON.stringify(err),
            errortype: "product list error",
            endpoint: url,
            requestBody: reqBody,
        };
        data["product"] = "bd_dashboard";
        if (!environment["production"]) {
            return;
        }
        this.http.post(this.errUrl, data).subscribe(
            (res) => {
                console.log(res);
            },
            (err) => {
                if (err) {
                    console.log(err);
                }
            }
        );
    }

    /**
     * this method returns an observeable. this method gets data from a url and returns it
     * @param url the url from which data is to be fetched
     */
    getData(url) {
        return new Promise((resolve, reject) => {
            const httpOptions: { headers } = {
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    Authorization:
                        localStorage.getItem("token_type") +
                        " " +
                        localStorage.getItem("access_token"),
                }),
            };
            this.http.get(url, httpOptions).subscribe(
                (data) => {
                    resolve(data);
                },
                (err) => {
                    this.pushErrorLogToLambda(url, "", err);
                    reject(err);
                }
            );
        });
    }
}
