import { UtilService } from './shared/services/util.service';
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthGuard } from "./shared";
import { HttpServiceService } from "./shared/services/http-service.service";
import { ConstantsService } from "./shared/services/constants.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { CommonService } from "./shared/services/common.service";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { AmazingTimePickerModule } from "amazing-time-picker";
import { ConfirmationPopoverModule } from "angular-confirmation-popover";
import "angular2-navigate-with-data";
import { DatePipe } from "@angular/common";
import { ExcelService } from "./shared/services/excel.service";
import { NotifierModule } from "angular-notifier";
import 'hammerjs';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    imports: [
        CommonModule,
        NotifierModule.withConfig({
            position:{
                horizontal:{
                    position:"left"
                },
                vertical:{
                    position:"bottom"
                }
            },
            behaviour:{
                autoHide:false,
                onMouseover: 'pauseAutoHide',
                showDismissButton:true
            }
        }),
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: "toast-top-right",
            progressBar: true,
            progressAnimation:"decreasing",
            closeButton:true
        }),
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        AngularMultiSelectModule,
        AmazingTimePickerModule,
        ConfirmationPopoverModule.forRoot({
            confirmButtonType: "danger",
        }),
        NgSelectModule
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        AuthGuard,
        HttpServiceService,
        ConstantsService,
        CommonService,
        DatePipe,
        ExcelService,
        UtilService
    ],
    bootstrap: [AppComponent],
    entryComponents: [
    ],
})
export class AppModule { }
