import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { ConstantsService } from "./constants.service";
import { HttpServiceService } from "./http-service.service";
import { ToastrService } from "ngx-toastr";
import {
    DomSanitizer,
    SafeResourceUrl,
    SafeUrl,
} from "@angular/platform-browser";
import {
    NgbActiveModal,
    NgbModal,
    NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import * as _ from 'lodash';

@Injectable()
export class UtilService {

    modalOption: NgbModalOptions = {};
    today = new Date();
    tempVariableObj = {
        settlementObj: "Settlement Details",
        docVisibilityObj: "Document Visibility",
        "fileObj": "File Tracking",
        "claimTrackObj": "Claim And Query Tracking",
        "finalBillObj": "Final Bill Section",
        finalLetterObj: "Final Approved Amount",
        collectionObj: "Collection Section",
        "dischargeObj": "Discharge Section"
    }
    SRNumberArray:any = []
    categoryArray:any = []
    tempCategoryArray: any = []
    constructor(
        private http: HttpServiceService,
        private constants: ConstantsService,
        private toastr: ToastrService,
        private sanitizer: DomSanitizer,
        private modalService: NgbModal,
        private datePipe: DatePipe
    ) {
        this.modalOption.backdrop = "static";
        this.modalOption.keyboard = false;
    }

    checkValueExists(value){
        if(value){
            return value
        }else{
            return "NA"
        }
    }

    
}
