import { CommonService } from '../app/shared/services/common.service';
import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { NotifierService } from "angular-notifier";
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        private common: CommonService,
        private notifierService: NotifierService,
        private toastr:ToastrService
    ) {
        this.common.fetchLatestUserValues()
    }

    ngOnInit() {
        
    }
}
